import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";

export const Pay = () => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethodStripeEvent, setPaymentMethodStripeEvent] = useState();

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "FR",
        currency: "eur",
        total: {
          label: "Demo total",
          amount: 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ["link"],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log("result", result);
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (ev) => {
        setPaymentMethodStripeEvent(ev);
        const createOrderResponse = await axios.post(
          "https://fhpmm8eplc.execute-api.eu-west-3.amazonaws.com/createPi",
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log(
          "createOrderResponse",
          createOrderResponse?.data?.paymentIntentClientSecret
        );

        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            createOrderResponse?.data?.paymentIntentClientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
        if (confirmError) {
          console.log("confirmError");
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
        } else {
          console.log("success");

          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(
              createOrderResponse?.paymentIntentClientSecret
            );
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
          }
        }
      });
    }
  }, [stripe]);

  const show = () => {
    console.log("show", { paymentRequest });
    paymentRequest?.show();
  };

  useEffect(() => {
    if (paymentRequest) {
      console.log("add");
      window.addEventListener(
        "message",
        () => {
          console.log("GOT it", { paymentRequest });
          paymentRequest?.show();
        },
        false
      );
    }
  }, [paymentRequest]);

  // Use a traditional checkout form.
  // return <button onClick={show}>fake apple pay</button>;
  return <button onClick={show}> </button>;
};
