import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Pay } from "./pay";

const stripePromise = loadStripe(
  "pk_test_51JAUYBFq1BiPgN2KpBxplQmTSe6UwqoMWlhX7nWrIaSnRqb06mZk6mq5EYyIgieUk9hZjmZNBBF6ayw9FgDgf7H800NWgxzLfW"
);

function App() {
  return (
    <div className="App">
      <iframe src="https://apple-pay-nine.vercel.app/" allow="payment" />
      <Elements stripe={stripePromise}>
        <Pay></Pay>
    </Elements>
    </div>
  );
}

export default App;
